const HttpCode = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  ACCESS_DENIED: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500
}
const ErrorService = {
  isOk(response) {
    return response.status === HttpCode.OK;
  },
  isBad(response) {
    return !this.isOk(response);
  },

  isBadRequest(response) {
    return response.status === HttpCode.BAD_REQUEST;
  },

  isAccessDenied(response) {
    return response.status === HttpCode.ACCESS_DENIED;
  },

  isUnauthorized(response) {
    return response.status === HttpCode.UNAUTHORIZED;
  },

  isServerError(response) {
    return response.status === HttpCode.SERVER_ERROR;
  },

  /**
   *
   * @param response
   * @returns {boolean}
   */
  isError(data) {
    let msg = data.msg;
    let common = msg.common;
    let errorCode = common.errorCode;
    return 'E0000' !== errorCode;
  },

  /**
   *
   * @param response
   * @returns {string|number}
   */
  getErrorCode(data) {
    if (ErrorService.isError(data)) {
      let msg = data.msg;
      let common = msg.common;
      let errorCode = common.errorCode;
      return errorCode;
    }
    return '';
  },

  /**
   *
   * @param response
   * @returns {string|*}
   */
  getErrorMessage(data) {
    if (ErrorService.isError(data)) {
      let msg = data.msg;
      let common = msg.common;
      let errorMessage = common.errorMessage;
      return errorMessage;
    }
    return '';
  }
}

export default ErrorService;