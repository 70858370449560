import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import ErrorService from "../services/error.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const LOAD_USER = "loadUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const REMEMBER_USER_ID = 'rememberUserId';
export const FORGOT_USER_ID = 'forgotUserId';

const state = {
  errors: null,
  user: {
    id: '',
    userId: '',
    userName: '',
    tel: '',
    phone: '',
    email: '',
    role: '',
    loginAt: '',
    accessToken: '',
  },
  isAuthenticated: JwtService.getAccessToken().length > 0,
  rememberUserId: JwtService.getRememberedUserId(),
};

const getters = {
  currentUser(state) {
    return state.user || {};
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isAdminRole(state) {
    return state.user.role === 'ROLE_ADMIN';
  },
  isUserRole(state) {
    return state.user.role === 'ROLE_USER';
  },
  currentUserRole(state) {
    return state.user.role;
  },
  rememberUserId(state) {
    return state.rememberUserId;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/login", credentials).then(({data}) => {
        if (!ErrorService.isError(data)) {
          let msg = data.msg;
          let body = msg.data;
          context.commit(SET_AUTH, body);
        }
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      context.commit(PURGE_AUTH);
      resolve();
    });
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials).then(({data}) => {
        context.commit(SET_AUTH, data);
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data.errors);
      });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (!state.isAuthenticated) {
        return reject();
      }
      let existToken = JwtService.parseAccessToken();
      if (Object.keys(existToken).length === 0) {
        return reject();
      }
      context.commit(SET_AUTH, existToken);
      resolve();
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({data}) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [REMEMBER_USER_ID](context, payload) {
    JwtService.rememberUserId(payload);
  },
  [FORGOT_USER_ID]() {
    JwtService.forgotUserId();
  },
  [LOAD_USER](context) {
    let user = JwtService.getUser();
    context.commit(SET_AUTH, user);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;

    state.user = data;
    state.errors = {};
    JwtService.saveUser(data);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
