<template>
    <v-app class="full-sidebar" id="materialpro">
        <router-view/>
    </v-app>

</template>

<script>
    export default {
        name: 'App',
        components: {},
        metaInfo: {
            title: 'B2B Solution'
        }
    };
</script>
