import ApiService from "@/services/api.service";
import ErrorService from "@/services/error.service";

const ID_TOKEN_KEY = "id_token";
const REMEMBER_USER_ID = "remember_user_id";

export const parseAccessToken = () => {
  let accessTokenData = window.sessionStorage.getItem(ID_TOKEN_KEY) || '{}';
  let tokenData = null;
  try {
    tokenData = JSON.parse(accessTokenData);
  } catch (error) {
    destroyToken();
  }
  if (!tokenData) {
    return {};
  }
  return tokenData;
}

export const getAccessToken = () => {
  let accessTokenData = parseAccessToken();
  if (!accessTokenData) {
    return '';
  }
  return accessTokenData.accessToken || '';
};


export const saveUser = (data) => {
  let tokenData = JSON.stringify(data);
  window.sessionStorage.setItem(ID_TOKEN_KEY, tokenData);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(ID_TOKEN_KEY);
};

export const rememberUserId = (userId) => {
  window.localStorage.setItem(REMEMBER_USER_ID, userId);
}

export const forgotUserId = () => {
  window.localStorage.removeItem(REMEMBER_USER_ID);
}

export const getRememberedUserId = () => {
  return window.localStorage.getItem(REMEMBER_USER_ID);
}

export const getUser = () => {
  let accessTokenData = window.sessionStorage.getItem(ID_TOKEN_KEY) || '{}';
  let tokenData;
  try {
    tokenData = JSON.parse(accessTokenData);
  } catch (error) {
    tokenData = null;
  }
  if (!tokenData) {
    return {};
  }
  return tokenData;
}

export const verify = () => {
  return new Promise((resolve, reject) => {
    const token = getAccessToken();
    if (!token) {
      reject();
    }
    ApiService.post('/auth/verify', {
      accessToken: token
    }).then(({data}) => {
      if (ErrorService.isOk(data)) {
        resolve();
        return;
      }
      reject();
    })
  })
}

export default {
  getAccessToken,
  saveUser,
  destroyToken,
  rememberUserId,
  forgotUserId,
  getRememberedUserId,
  parseAccessToken,
  getUser,
  verify,
};
