import Vue from "vue";
Vue.filter('mobileFilter', function(value) {
  value = `${value}`;
  if (value.length === 11) {
    return value.substring(0, 3) + '-' + value.substring(3, 7) + '-' + value.substring(7);
  }
  return value;
})

Vue.filter('telFilter', function(value) {
  value = `${value}`;
  if (value.length === 10) {
    return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6);
  }
  if (value.length === 11) {
    return value.substring(0, 3) + '-' + value.substring(3, 7) + '-' + value.substring(7);
  }
  return value;
})

Vue.filter('commaFilter', function(value) {
  if (!value) {
    return value;
  }
  let num = Number(value);
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
})