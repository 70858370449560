import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";

import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css'
import {VERIFY_AUTH} from "@/store/auth.module";
import store from "@/store";
import ApiService from "@/services/api.service";
import underscore from 'vue-underscore';
import "./filter";

Vue.use(require('vue-moment'));
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#41b882', cancelButtonColor: '#ff7674',
});

Vue.use(underscore);

ApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  let path = to.path;
  if (path === '/auth/login') {
    next();
    return;
  }
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
    next();
  }).catch(() => {
    router.push({
      path: '/auth/login'
    });
  })

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  vuetify, store, router, render: (h) => h(App),
}).$mount("#app");
