import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import NProgress from "nprogress";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: '/',
      redirect: "/auth/login"
    },
    {
      path: '/app',
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: 'appEventMain',
          path: 'event/schedule',
          component: () => import('@/views/event/Main')
        },
        {
          name: 'EventForm',
          path: 'event/form',
          component: () => import('@/views/event/Form')
        },
        {
          name: 'MyInfo',
          path: 'user/myInfo',
          component: () => import('@/views/user/MyInfo')
        }
      ]
    },
    {
      name: 'admin',
      path: "/admin",
      redirect: "/admin/dashbord",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: 'eventSchedule',
          path: 'event/schedule',
          component: () => import('@/views/event/Main')
        },
        {
          name: 'adminEventMain',
          path: 'event/main',
          component: () => import('@/views/event/Main')
        },
        {
          name: 'eventEdit',
          path: 'event/Edit',
          component: () => import('@/views/event/Edit')
        },
        {
          name: 'boothManage',
          path: 'booth/manage',
          component: () => import('@/views/booth/List')
        },
        {
          name: 'boothEdit',
          path: 'booth/edit',
          component: () => import('@/views/booth/Edit')
        },
        {
          name: 'boothAdd',
          path: 'booth/add',
          component: () => import('@/views/booth/Add')
        },
        {
          name: 'itemManage',
          path: 'item/manage',
          component: () => import('@/views/item/List')
        },
        {
          name: 'itemEdit',
          path: 'item/edit',
          component: () => import('@/views/item/Edit')
        },
        {
          name: 'itemAdd',
          path: 'item/add',
          component: () => import('@/views/item/Add')
        },
        {
          name: 'UserList',
          path: 'user/list',
          component: () => import('@/views/system/user/List'),
        },
        {
          name: 'UserAdd',
          path: 'user/add',
          component: () => import('@/views/system/user/Add'),
        },
        {
          name: 'UserEdit',
          path: 'user/edit',
          component: () => import('@/views/system/user/Edit'),
        },
        {
          name: 'EmpList',
          path: 'employee/list',
          component: () => import('@/views/system/employee/List'),
        },
        {
          name: 'EmpAdd',
          path: 'employee/add',
          component: () => import('@/views/system/employee/Add'),
        },
        {
          name: 'EmpEdit',
          path: 'employee/edit',
          component: () => import('@/views/system/employee/Edit'),
        },
      ]
    },
    {
      path: '/auth',
      component: () => import("@/layouts/LoginLayout"),
      children: [
        {
          path: "/auth/login",
          component: () => import("@/views/auth/Login"),
        }
      ]

    },
    {
      path: "*",
      component: () => import("@/views/errors/Error"),
    },
  ],
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
