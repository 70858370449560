<template>
    <v-list-group
            :active-class="`primary white--text`"
            :group="group"
            :prepend-icon="item.icon"
            :sub-group="subGroup"
    >
        <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title v-text="item.title"/>
            </v-list-item-content>
        </template>

        <template v-for="(child, i) in children">
            <BaseItemSubGroup
                    :item="child"
                    :key="`sub-group-${i}`"
                    class="second-dd"
                    v-if="child.children"
            />

            <BaseItem
                    :item="child"
                    :key="`item-${i}`"
                    text
                    v-else
            />
        </template>
    </v-list-group>
</template>

<script>
    // Utilities
    import kebabCase from 'lodash/kebabCase'

    export default {
        name: 'BaseItemGroup',

        inheritAttrs: false,

        props: {
            item: {
                type: Object,
                default() {
                    return {
                        avatar: undefined,
                        group: undefined,
                        title: undefined,
                        roles: undefined,
                        children: [],
                    };
                },
            },
            subGroup: {
                type: Boolean,
                default: false,
            },
            text: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            children() {
                return this.item.children.map(item => ({
                    ...item,
                    to: !item.to ? undefined : `${this.item.group}/${item.to}`,
                }))
            },
            group() {
                return this.genGroup(this.item.children)
            },
        },

        methods: {
            genGroup(children) {
                return children
                    .filter(item => item.to)
                    .map(item => {
                        const parent = item.group || this.item.group
                        let group = `${parent}/${kebabCase(item.to)}`

                        if (item.children) {
                            group = `${group}|${this.genGroup(item.children)}`
                        }

                        return group
                    }).join('|')
            },
        },
    }
</script>

<style>

</style>
