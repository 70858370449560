import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {replace} from 'lodash'
import JwtService from './jwt.service'

const qs = require('qs')

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.timeout = 30000
    axios.defaults.timeout = 30000
    //Vue.axios.defaults.baseURL = (process.env.NODE_ENV === 'production' ? '/' : 'http://128.199.216.244/')
    Vue.axios.defaults.baseURL = (process.env.NODE_ENV === 'production' ? '/' : 'https://rapoad.cafe24.com')
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    Vue.axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.baseURL = (process.env.NODE_ENV === 'production' ? '/' : 'https://rapoad.cafe24.com')
    if (process.env.NODE_ENV !== 'production') {
      //this.setHeader()
      axios.interceptors.response.use(
          function (response) {
            let code = response.status
            if (code === 401 || code === 500 || code === 404) {
              window.location.href = `/error/error${code}`
              return false
            }

            return response
          },
          function (error) {
            let response = error.response
            if (!response) {
              window.location.href = '/error/network-error'
              return false
            }

            const code = response.status || 500
            if (code === 401 || code === 500 || code === 404) {
              window.location.href = `/error/error${code}`
              return false
            }
            return error
          }
      )
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    axios.defaults.headers.common['Authorization'] = JwtService.getAccessToken()
    Vue.axios.defaults.headers.common['Authorization'] = JwtService.getAccessToken()
  },

  /**
   * Send the GET HTTP request
   * @param url
   * @param [params]
   * @returns Promise
   */
  get(url, params) {
    let data = params || {}
    let apiUrl = `/api/${url}`
    if (data.length > 0) {
      apiUrl = apiUrl + '?' + qs.stringify(params)
    }
    apiUrl = replace(apiUrl, '//', '/')
    let options = {
      params: data,
    }
    options['timeout'] = 30000;

    if (process.env.NODE_ENV === 'production') {
      options['headers'] = {
        'Authorization': JwtService.getAccessToken()
      }
    }
    return axios.get(apiUrl, options)
  },

  /**
   * Set the POST HTTP request
   * @param url
   * @param params
   * @param isJson
   * @returns {*}
   */
  post(url, params, isJson) {
    let apiUrl = `/api/${url}`
    apiUrl = replace(apiUrl, '//', '/')
    let options = {
      headers: {
        'Content-Type': isJson ? 'application/json'
            : 'application/x-www-form-urlencoded;charset=UTF-8',
      }
    }
    options['timeout'] = 30000;

    if (process.env.NODE_ENV === 'production') {
      options.headers['Authorization'] = JwtService.getAccessToken()
    }
    return axios.post(apiUrl, isJson ? params : qs.stringify(params), options)
  },

  /**
   * Send the PUT HTTP request
   * @param url
   * @param data
   * @param [isForm] boolean
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(url, data, isForm) {
    let apiUrl = `/api/${url}`
    apiUrl = replace(apiUrl, '//', '/')
    let options = {
      headers: {
        'Content-Type': isForm
            ? 'application/x-www-form-urlencoded;charset=UTF-8'
            : 'application/json',
      }
    }

    if (process.env.NODE_ENV === 'production') {
      options.headers['Authorization'] = JwtService.getAccessToken()
    }

    return axios.put(apiUrl, isForm ? qs.stringify(data) : data, options)
  },

  /**
   * Send the DELETE HTTP request
   * @param url
   * @param params
   * @param [isForm] boolean
   * @returns {*}
   */
  delete(url, params, isForm) {
    let apiUrl = `/api/${url}`
    apiUrl = replace(apiUrl, '//', '/')
    let options = {
      headers: {
        'Content-Type': isForm
            ? 'application/x-www-form-urlencoded;charset=UTF-8'
            : 'application/json',
      }
    }

    if (process.env.NODE_ENV === 'production') {
      options.headers['Authorization'] = JwtService.getAccessToken()
    }
    return axios.post(apiUrl, isForm ? qs.stringify(params) : params, options)
  }
}

export default ApiService
